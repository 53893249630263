<template>
  <b-modal
      no-close-on-backdrop
      centered
      hide-header-close
      ok-variant="dark"
      title="Kayıt Bilgileri"
      modal-class="modal-dark my-modal"
      v-model="isPreviewModal"
      size="lg"
      scrollable
      no-close-on-esc
      @close.prevent="closeModal">

    <div>
      <b-tabs pills>
        <b-tab active id="printThis">
          <template #title>
            <feather-icon icon="InboxIcon"/>
            <span>Genel</span>
          </template>

          <b-button
              id="print-button"
              class="mr-1 float-lg-right"
              variant="primary"
              @click="print">
            <feather-icon icon="PrinterIcon" size="16"/>
            <span class="align-middle" role="button"> Yazdır</span>
          </b-button>

          <table class="mt-2 mt-xl-0 w-100">
            <tr class="border-bottom">
              <th class="pb-50"><span class="font-weight-bold">Form No</span></th>
              <td class="py-50">{{ previewData.ficheNo }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Form Adı</span></th>
              <td class="py-50">{{ previewData.formName }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Müşteri</span></th>
              <td class="py-50">{{ previewData.customerName }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">İlgili Kişi</span></th>
              <td class="py-50">{{ previewData.customerContactName }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Veriliş Tarihi</span></th>
              <td class="py-50">
                <date-column-table :data="previewData.docDate"></date-column-table>
              </td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Teslim Tarihi</span></th>
              <td class="py-50">
                <date-column-table :data="previewData.dueDate"></date-column-table>
              </td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Amaç</span></th>
              <td class="py-50">{{ previewData.purpose }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Açıklama</span></th>
              <td class="py-50">{{ previewData.description }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold text-info">Geri Bildirim</span></th>
              <td class="py-50">{{ previewData.formRequestResultName }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold text-info">Geri Bildirim Açıklaması</span></th>
              <td class="py-50">{{ previewData.resultDescription }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Durum</span></th>
              <td class="py-50">
                <b-badge pill :variant="`light-${recordFormStatusVariant(previewData)}`" class="text-capitalize">
                  {{ previewData.isCancelled ? 'İptal Edildi' : previewData.formStatusName }}
                </b-badge>
              </td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Durum Açıklaması</span></th>
              <td class="py-50">{{ previewData.statusDescription }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Tamamlama Zamanı</span></th>
              <td class="py-50">
                <date-time-column-table :data="previewData.completedDate"></date-time-column-table>
              </td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Tamamlayan Kullanıcı</span></th>
              <td class="py-50">{{ previewData.completedUser }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Kayıt Sahibi</span></th>
              <td class="py-50">{{ previewData.ownerName }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Görevi Atama Zamanı</span></th>
              <td class="py-50">
                <date-time-column-table :data="previewData.assignedDate"></date-time-column-table>
              </td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Görevi Atayan Kullanıcı</span></th>
              <td class="py-50">{{ previewData.assignedUser }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Görevlendirilen Kullanıcı</span></th>
              <td class="py-50">{{ previewData.executingUser }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Oluşturma Zamanı</span></th>
              <td class="py-50">
                <date-time-column-table :data="previewData.createdOn"></date-time-column-table>
              </td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Değiştirme Zamanı</span></th>
              <td class="py-50">
                <date-time-column-table :data="previewData.modifiedOn"></date-time-column-table>
              </td>
            </tr>
            <tr>
              <th class="pt-50"><span class="font-weight-bold">İptal Zamanı</span></th>
              <td class="pt-50">
                <date-time-column-table :data="previewData.cancelledOn"></date-time-column-table>
              </td>
            </tr>
          </table>

          <div id="print-demand" class="border mt-1 p-1 d-none" v-if="previewData.questionGroups!=null && previewData.questionGroups.length > 0">
            <div v-for="(group, index) in previewData.questionGroups" :key="group.formQuestionGroupId" class="">
              <span class="lead collapse-title">
                <b-avatar size="32" :text="(index+1).toString()" :variant="`light-primary`"/>
                {{ group.questionGroupName }}
              </span>

              <b-table
                  class="mb-1 mt-1 ml-5 pr-5"
                  :items="group.questions"
                  :fields="questionFields"
                  striped responsive bordered hover>

                <template #cell(questionName)="data">
                  <div class="text-nowrap">
                    {{ data.item.required ? data.value + ' *' : data.value }}
                  </div>
                </template>

                <template #cell(answer)="data">
                  <b-form-checkbox v-model="data.item.answer" switch inline v-if="data.item.questionTypeId === 'CHECKBOX'" value="true" unchecked-value="false" disabled/>
                  <div class="text-nowrap" v-if="data.item.questionTypeId !== 'CHECKBOX'">
                    {{ data.value }}
                  </div>
                </template>

                <template #cell(description)="data">
                  <div class="text-nowrap">
                    {{ data.value }}
                  </div>
                </template>

              </b-table>
            </div>
          </div>

        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="InboxIcon"/>
            <span>Talepler</span>
          </template>
          <div class="border mt-1 p-1" v-if="previewData.questionGroups!=null && previewData.questionGroups.length > 0">
            <div v-for="(group, index) in previewData.questionGroups" :key="group.formQuestionGroupId" class="">
              <span class="lead collapse-title">
                <b-avatar size="32" :text="(index+1).toString()" :variant="`light-primary`"/>
                {{ group.questionGroupName }}
              </span>

              <b-table
                  class="mb-1 mt-1 ml-5 pr-5"
                  :items="group.questions"
                  :fields="questionFields"
                  striped responsive bordered hover>

                <template #cell(questionName)="data">
                  <div class="text-nowrap">
                    {{ data.item.required ? data.value + ' *' : data.value }}
                  </div>
                </template>

                <template #cell(answer)="data">
                  <b-form-checkbox v-model="data.item.answer" switch inline v-if="data.item.questionTypeId === 'CHECKBOX'" value="true" unchecked-value="false" disabled/>
                  <div class="text-nowrap" v-if="data.item.questionTypeId !== 'CHECKBOX'">
                    {{ data.value }}
                  </div>
                </template>

                <template #cell(description)="data">
                  <div class="text-nowrap">
                    {{ data.value }}
                  </div>
                </template>

              </b-table>
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="FileIcon"/>
            <span class="mr-1">Dökümanlar</span>
            <b-badge v-if="previewData.documents && previewData.documents.length > 0" variant="warning" class="float-right">
              {{ previewData.documents.length }}
            </b-badge>
          </template>

          <b-row class="border-bottom border-top p-1">
            <b-col cols="12" md="4" lg="4" class="pl-0">
              <b-button variant="warning" @click="$refs.refInputEl.click()">
                <feather-icon icon="PlusSquareIcon" size="16"/>
                <span class="align-middle" role="button"> Yeni Döküman Ekle</span>
              </b-button>
              <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @input="addNewFile"
                  multiple>
            </b-col>
          </b-row>

          <b-card no-body class="border mt-1">
            <b-table
                :items="previewData.documents"
                :fields="documentFields"
                class="mb-0"
                striped
                responsive
                bordered
                hover>

              <template #cell(createdOn)="data">
                <date-time-column-table :data="data.value"></date-time-column-table>
              </template>

              <template #cell(name)="data">
                <div class="text-nowrap">
                  {{ data.value }}
                </div>
              </template>

              <template #cell(fileType)="data">
                <div class="text-nowrap">
                  {{ data.value }}
                </div>
              </template>

              <template #cell(fileSize)="data">
                <div class="text-nowrap">
                  {{ data.value }}
                </div>
              </template>

              <template #cell(userName)="data">
                <div class="text-nowrap">
                  {{ data.value }}
                </div>
              </template>

              <template #cell(isCancelled)="data">
                <boolean-column-table :data="data.value"></boolean-column-table>
              </template>

              <template #cell(filePath)="data">
                <div class="text-nowrap">
                  <b-button variant="outline-primary" target="_blank" :href="getApiFile(data.value)" class="mr-1">
                    <feather-icon icon="DownloadCloudIcon" class="mr-50"/>
                    <span class="align-middle">Görüntüle</span>
                  </b-button>
                  <b-button variant="danger" @click="documentCancel(data.item)">
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">İptal</span>
                  </b-button>
                </div>
              </template>

            </b-table>
          </b-card>
        </b-tab>
      </b-tabs>
    </div>

    <div class="my-1"></div>

    <template #modal-footer="">
      <b-row class="d-flex justify-content-end align-items-center">
        <b-button class="mr-1" variant="outline-secondary" @click="$emit('update:is-preview-modal', false)">
          <feather-icon icon="XCircleIcon" size="16"/>
          <span class="align-middle" role="button"> Kapat</span>
        </b-button>
      </b-row>
    </template>

    <Overlay :busy="busy"></Overlay>
  </b-modal>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {BAvatar, BBadge, BButton, BCard, BCol, BFormCheckbox, BFormGroup, BModal, BRow, BTab, BTable, BTabs} from "bootstrap-vue";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {recordStatusVariant, recordStatusFormat, recordFormStatusVariant, getApiFile} from "@core/utils/filter"
import {ActiveColumnTable, DateTimeColumnTable, DateColumnTable, BooleanColumnTable} from "@/components/Table";
import Vue from "vue";
import vSelect from "vue-select";

export default {
  components: {
    BFormGroup, BCol, vSelect,
    BFormCheckbox,
    BCard,
    BAvatar,
    BTab,
    BTabs,
    BTable,
    BBadge,
    DateTimeColumnTable,
    DateColumnTable,
    ActiveColumnTable,
    Overlay,
    BooleanColumnTable,

    BRow,
    BButton,
    BModal
  },
  model: [
    {
      prop: 'isPreviewModal',
      event: 'update:is-preview-modal',
    },
    {
      prop: 'dataRecord',
      event: 'update:data-record',
    }
  ],
  props: {
    isPreviewModal: {
      type: Boolean,
      required: true,
    },
    dataRecord: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)
    const previewData = ref({});

    watch(() => props.dataRecord, (dataValue) => {
      if (dataValue.id !== undefined) {
        busy.value = true
        store.dispatch('store/previewItem', {id: dataValue.id}).then(response => {
          previewData.value = response.data.data
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
          if (error.response.status === 403) {
            emit('update:is-preview-modal', false)
            emit('update:data-record', {})
          }
        }).finally(message => {
          busy.value = false
        })
      }
    })

    const questionFields = [
      {key: 'questionName', label: 'Soru', thStyle: {width: "20%"}},
      {key: 'answer', label: 'Cevap'},
      {key: 'description', label: 'Açıklama', thStyle: {width: "30%"}},
    ];

    const documentFields = [
      {key: 'name', label: 'İsim'},
      {key: 'fileType', label: 'Tür'},
      {key: 'fileSize', label: 'Boyut'},
      {key: 'userName', label: 'Ekleyen'},
      {key: 'createdOn', label: 'Tarih'},
      {key: 'isCancelled', label: 'İptal'},
      {key: 'filePath', label: 'İndir'},
    ];

    const print = () => {
      document.getElementById("print-demand").classList.remove("d-none");

      if (document.querySelectorAll("#print-demand").length > 1) {
        document.querySelectorAll("#print-demand")[document.querySelectorAll("#print-demand").length - 1].classList.remove("d-none");
      }

      document.getElementById("print-button").classList.add("d-none");

      let $printThis = document.querySelector("#printThis");

      if (document.querySelectorAll("#printThis").length > 1) {
        $printThis = document.querySelectorAll("#printThis")[document.querySelectorAll("#printThis").length - 1];
      }

      let domClone = $printThis.cloneNode(true);

      let $printSection = document.getElementById("printSection");

      if (!$printSection) {
        $printSection = document.createElement("div");
        $printSection.id = "printSection";
        document.body.appendChild($printSection);
      }

      $printSection.innerHTML = "";
      $printSection.appendChild(domClone);

      window.print();

      document.getElementById("print-demand").classList.add("d-none");
      document.getElementById("print-button").classList.remove("d-none");
    }

    const documentCancel = (dataItem) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt iptal edilecektir!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, İptal',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/cancelDocumentItem', {formRequestId: dataItem.formRequestId, id: dataItem.id}).then(response => {
            if (response.data.success) {
              dataItem.isCancelled = true;
              Vue.swal({
                icon: 'success',
                title: 'Başarılı!',
                text: 'Kayıt iptal edildi.',
                confirmButtonText: 'Tamam',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const refInputEl = ref(null)
    const addNewFile = () => {
      const documents = refInputEl.value.files;

      const formData = new FormData();
      for (let i = 0; i < documents.length; i++) {
        let file = documents[i];
        formData.append('documents[' + i + ']', file);
      }

      busy.value = true
      store.dispatch('store/addDocumentItem', {formRequestId: props.dataRecord.id, dataInfo: formData}).then(response => {
        emit('update:is-preview-modal', false)
        emit('update:data-record', {})

        Vue.swal({
          icon: 'success',
          title: 'Başarılı!',
          text: 'Ekleme işlemi tamamlandı.',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: error.response.data.message,
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })
    }

    const closeModal = () => {
      document.getElementById("printThis").innerHTML = '';
      emit('update:is-preview-modal', false);
      emit('update:data-record', {});
      previewData.value = {};
    }

    return {
      busy,
      previewData,
      questionFields,
      documentFields,
      refInputEl,

      recordStatusFormat,
      recordStatusVariant,
      getApiFile,
      print,
      documentCancel,
      recordFormStatusVariant,
      addNewFile,
      closeModal,
    }
  },
}
</script>

<style lang="scss">
.my-modal .modal-body {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen {
  #printSection {
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #printSection, #printSection * {
    visibility: visible;
  }
  #printSection {
    position: absolute;
    left: 0;
    top: 0;
  }
}

@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@media print {
  html, body {
    height: 99%;
  }
}

</style>
