import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/formrequests', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/formrequests/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/formrequests/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/formrequests', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/formrequests', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/formrequests/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        assignItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/formrequests/assign', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        completeItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/formrequests/complete', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        changeStatusItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/formrequests/change-status', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/formrequests/cancel/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelDocumentItem(ctx, {formRequestId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/formrequestdocuments/cancel/${formRequestId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addDocumentItem(ctx, {formRequestId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/formrequestdocuments/${formRequestId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        export(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/formrequests/export', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        feedbackItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/formrequests/feedback', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
