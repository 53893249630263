<template>

  <b-card no-body>
    <div class="m-1">
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 per-page-select">
          <b-row>
            <v-select
                v-model="perPage"
                class="per-page-selector d-inline-block mx-50"
                :options="perPageOptions"
                :clearable="false"/>
            <b-col cols="12" lg="3" md="3" class="d-flex justify-content-start align-items-start p-0 mx-50">
              <flat-pickr v-model="startDate" class="form-control" :config="{altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
            </b-col>
            <b-col cols="12" lg="3" md="3" class="d-flex justify-content-start align-items-start p-0">
              <flat-pickr v-model="endDate" class="form-control" :config="{altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Arama..."/>
            <header-table :new-route="{ name: 'form-request-add' }" :re-fetch-data="reFetchData" :export-excel="exportExcel"></header-table>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
        ref="refListTable"
        primary-key="id"
        empty-text="Kayıt bulunamadı"
        :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
        :items="fetchList"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive striped bordered hover show-empty>

      <template #cell(id)="data">
        <id-column-table :data="data.value" :route-name="'form-request-edit'"></id-column-table>
      </template>

      <template #cell(docDate)="data">
        <date-column-table :data="data.value"></date-column-table>
      </template>

      <template #cell(ficheNo)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(formName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(customerName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(ownerName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(dueDate)="data">
        <date-column-table :data="data.value"></date-column-table>
      </template>

      <template #cell(formStatusName)="data">
        <b-badge pill :variant="`light-${recordFormStatusVariant(data.item)}`" class="text-capitalize">
          {{ data.item.isCancelled ? 'İptal Edildi' : data.value }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-dropdown text="Dropdown" variant="link" no-caret toggle-class="p-0" dropleft>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
            </template>
            <b-dropdown-item :to="{ name: 'form-request-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Değiştir</span>
            </b-dropdown-item>
            <b-dropdown-item @click="assignShowModal(data.item)">
              <feather-icon icon="UserCheckIcon"/>
              <span class="align-middle ml-50">Kullanıcıya Ata</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeStatusShowModal(data.item)">
              <feather-icon icon="ActivityIcon"/>
              <span class="align-middle ml-50">Durum Değiştir</span>
            </b-dropdown-item>
            <b-dropdown-item @click="feedBackShowModal(data.item)">
              <feather-icon icon="GitPullRequestIcon"/>
              <span class="align-middle ml-50">Geri Bildirim</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'form-request-revision', params: { id: data.item.id } }">
              <feather-icon icon="RepeatIcon"/>
              <span class="align-middle ml-50">Revizyon</span>
            </b-dropdown-item>
            <b-dropdown-item @click="completeShowModal(data.item)">
              <feather-icon icon="CheckCircleIcon"/>
              <span class="align-middle ml-50">Tamamla</span>
            </b-dropdown-item>
            <b-dropdown-item @click="cancel(data.item)">
              <feather-icon icon="XIcon"/>
              <span class="align-middle ml-50">İptal</span>
            </b-dropdown-item>
            <remove-button-table :data="data" :re-fetch-data="reFetchData" :dropdown="true"></remove-button-table>
          </b-dropdown>
          <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewRecord(data.item)" class="mx-1"/>
          <b-tooltip title="Görüntüle" class="cursor-pointer" :target="`row-${data.item.id}-prev`"/>
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <footer-table :data-meta="dataMeta"></footer-table>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number last-number>

            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>

          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <Overlay :busy="busy"></Overlay>
    <preview :is-preview-modal.sync="isPreviewModal" :data-record.sync="dataPreviewRecord"></preview>

    <b-modal v-model="assignModalShow" ref="assign-modal" title="Kullanıcıya Ata" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @ok="handleAssignModal" centered no-close-on-backdrop>
      <b-form @submit.stop.prevent="submitAssignModal">
        <b-form-group label="Kullanıcı">
          <v-select v-model="assignUserId" :options="userOptions" :reduce="val => val.value" :clearable="false"/>
        </b-form-group>
        <b-form-group label="Açıklama">
          <b-form-textarea v-model="assignDescription" placeholder="Açıklama" trim/>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal v-model="changeStatusModalShow" ref="change-status-modal" title="Durum Değiştir" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @ok="handleChangeStatusModal" centered no-close-on-backdrop>
      <b-form @submit.stop.prevent="submitChangeStatusModal">
        <b-form-group label="Durum">
          <v-select v-model="changeStatusId" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
        </b-form-group>
        <b-form-group label="Açıklama">
          <b-form-textarea v-model="changeStatusDescription" placeholder="Açıklama" trim/>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal v-model="completeModalShow" ref="complete-modal" title="Formu Tamamla" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @ok="handleCompleteModal" centered no-close-on-backdrop>
      <b-form @submit.stop.prevent="submitCompleteModal">
        <b-form-group label="Dosya" description="Birden fazla dosya seçimi yapabilirsiniz...">
          <b-form-file id="completeFiles" placeholder="Dosya seçimi yapınız..." drop-placeholder="Dosyalarınızı sürükleyerek buraya bırakabilirsiniz..." multiple/>
        </b-form-group>
        <b-form-group label="Açıklama">
          <b-form-textarea v-model="completeDescription" placeholder="Açıklama" trim/>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal v-model="feedBackModalShow" ref="feedback-modal" title="Geri Bildirim" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @ok="handleFeedBackModal" centered no-close-on-backdrop>
      <b-form @submit.stop.prevent="submitFeedBackModal">
        <b-form-group label="Sonuç">
          <v-select v-model="feedBackStatusId" :options="feedBackOptions" :reduce="val => val.value" :clearable="false"/>
        </b-form-group>
        <b-form-group label="Açıklama">
          <b-form-textarea v-model="feedBackDescription" placeholder="Açıklama" trim/>
        </b-form-group>
      </b-form>
    </b-modal>

  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination, BTooltip, BBadge, BModal, BFormGroup, BForm, BFormFile, BFormTextarea,
} from 'bootstrap-vue'
import {
  FooterTable, RemoveButtonTable, EditButtonTable, DateColumnTable, ActiveColumnTable, IdColumnTable, HeaderTable, BooleanColumnTable
} from '@/components/Table';

import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from '@/store'
import useList from './useList'
import storeModule from './store'
import userModule from "@/views/system/user/store"
import definitionModule from "@/views/system/definition/store"
import Overlay from "@/components/Overlay.vue";
import Preview from "@/views/form_request/Preview.vue";
import Vue from "vue";
import {getApiFile, recordFormStatusVariant} from "@core/utils/filter";
import {toastMessage} from "@core/utils/utils";
import {useToast} from "vue-toastification/composition";
import flatPickr from 'vue-flatpickr-component'
import router from "@/router";

export default {
  components: {
    BFormTextarea,
    BFormFile,
    BForm,
    BFormGroup,
    BModal,
    BBadge,
    BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination, BTooltip,
    flatPickr,

    HeaderTable, FooterTable, RemoveButtonTable, EditButtonTable, DateColumnTable, ActiveColumnTable, IdColumnTable, BooleanColumnTable,

    vSelect,
    Overlay,
    Preview,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_USER_MODULE_NAME = 'store-user'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_USER_MODULE_NAME, userModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_USER_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
      }
    })

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      startDate,
      endDate,

      fetchList,
      reFetchData,
    } = useList()

    const toast = useToast()
    const selectedForm = ref(null)

    const dataPreviewRecord = ref({})
    const isPreviewModal = ref(false)
    const previewRecord = (dataItem) => {
      dataPreviewRecord.value = dataItem
      isPreviewModal.value = true
    }

    const cancel = (dataItem) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt iptal edilecektir!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, İptal',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/cancelItem', {id: dataItem.id}).then(response => {
            if (response.data.success) {
              reFetchData()
              Vue.swal({
                icon: 'success',
                title: 'Başarılı!',
                text: 'Kayıt iptal edildi.',
                confirmButtonText: 'Tamam',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const userOptions = ref([])
    const assignUserId = ref(null)
    const assignDescription = ref(null)
    const assignModalShow = ref(false)
    const assignShowModal = (dataItem) => {
      selectedForm.value = dataItem
      userOptions.value = []
      assignDescription.value = null;

      busy.value = true
      store.dispatch('store-user/fetchItems').then(response => {
        response.data.data.forEach((value, index) => {
          if (value.isActive) {
            userOptions.value.push({label: value.firstName + ' ' + value.lastName, value: value.id})
          }
        })
        assignModalShow.value = true
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }
    const handleAssignModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      submitAssignModal()
    }
    const submitAssignModal = () => {
      busy.value = true
      store.dispatch('store/assignItem', {
        id: selectedForm.value.id,
        executingUserId: assignUserId.value,
        description: assignDescription.value,
      }).then(response => {
        reFetchData()
        Vue.swal({
          icon: 'success',
          title: 'Başarılı!',
          text: 'Atama işlemi yapıldı.',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: error.response.data.message,
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })

      selectedForm.value = null
      assignUserId.value = null
      assignModalShow.value = false
    }

    const completeDescription = ref(null)
    const completeModalShow = ref(false)
    const completeShowModal = (dataItem) => {
      selectedForm.value = dataItem
      completeDescription.value = ''
      completeModalShow.value = true
    }
    const handleCompleteModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      submitCompleteModal()
    }
    const submitCompleteModal = () => {
      const documents = document.getElementById('completeFiles').files

      const formData = new FormData();
      for (let i = 0; i < documents.length; i++) {
        let file = documents[i];
        formData.append('documents[' + i + ']', file);
      }

      formData.append('id', selectedForm.value.id);
      formData.append('completedDescription', completeDescription.value);

      busy.value = true
      store.dispatch('store/completeItem', formData).then(response => {
        reFetchData()
        Vue.swal({
          icon: 'success',
          title: 'Başarılı!',
          text: 'Durum güncellendi.',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: error.response.data.message,
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })

      selectedForm.value = null
      completeDescription.value = ''
      completeModalShow.value = false
    }

    const statusOptions = ref([])
    const changeStatusId = ref(null)
    const changeStatusDescription = ref(null)
    const changeStatusModalShow = ref(false)
    const changeStatusShowModal = (dataItem) => {
      selectedForm.value = dataItem
      statusOptions.value = []
      changeStatusDescription.value = null;

      statusOptions.value.push({label: 'İşleme Alındı', value: 'PROCESSING'})
      statusOptions.value.push({label: 'Durduruldu', value: 'STOPPED'})
      statusOptions.value.push({label: 'Devam Ediyor', value: 'CONTINUES'})

      changeStatusModalShow.value = true
    }
    const handleChangeStatusModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      submitChangeStatusModal()
    }
    const submitChangeStatusModal = () => {
      busy.value = true
      store.dispatch('store/changeStatusItem', {
        id: selectedForm.value.id,
        formStatusId: changeStatusId.value,
        description: changeStatusDescription.value,
      }).then(response => {
        reFetchData()
        Vue.swal({
          icon: 'success',
          title: 'Başarılı!',
          text: 'Durum güncellendi.',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: error.response.data.message,
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })

      selectedForm.value = null
      changeStatusId.value = null
      changeStatusModalShow.value = false
    }
    const exportExcel = () => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Excele aktarım yapılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Aktar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/export', {
            startDate: startDate.value,
            endDate: endDate.value,
          }).then(response => {
            if (response.data.success) {
              window.open(getApiFile(response.data.data), '_blank')
            }
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const feedBackOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'FORM_REQUEST_RESULT'}).then(response => {
      response.data.data.forEach((value, index) => {
        feedBackOptions.value.push({label: value.name, value: value.key})
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const feedBackStatusId = ref(null)
    const feedBackDescription = ref(null)
    const feedBackModalShow = ref(false)
    const feedBackShowModal = (dataItem) => {
      selectedForm.value = dataItem

      feedBackStatusId.value = dataItem.formRequestResultId
      feedBackDescription.value = dataItem.resultDescription;

      feedBackModalShow.value = true
    }
    const handleFeedBackModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()

      if (feedBackStatusId.value === null || feedBackStatusId.value === '') {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Sonuç bilgisi seçilmelidir!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      } else {
        submitFeedBackModal()
      }
    }
    const submitFeedBackModal = () => {
      busy.value = true
      store.dispatch('store/feedbackItem', {
        id: selectedForm.value.id,
        resultId: feedBackStatusId.value,
        description: feedBackDescription.value,
      }).then(response => {
        reFetchData()
        Vue.swal({
          icon: 'success',
          title: 'Başarılı!',
          text: 'Geri bildirim kaydedildi.',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: error.response.data.message,
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })

      selectedForm.value = null
      feedBackStatusId.value = null
      feedBackModalShow.value = false
    }

    return {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      isPreviewModal,
      dataPreviewRecord,
      assignUserId,
      userOptions,
      assignModalShow,
      assignDescription,
      statusOptions,
      changeStatusId,
      changeStatusModalShow,
      changeStatusDescription,
      completeDescription,
      completeModalShow,
      startDate,
      endDate,

      fetchList,
      reFetchData,
      previewRecord,
      cancel,
      assignShowModal,
      handleAssignModal,
      submitAssignModal,
      changeStatusShowModal,
      recordFormStatusVariant,
      handleChangeStatusModal,
      submitChangeStatusModal,
      completeShowModal,
      handleCompleteModal,
      submitCompleteModal,
      exportExcel,

      feedBackModalShow,
      handleFeedBackModal,
      submitFeedBackModal,
      feedBackShowModal,
      feedBackStatusId,
      feedBackOptions,
      feedBackDescription,
    }
  }
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>